@font-face {
  font-family: rageItalic;
  src: url(./assets/RageItalic.woff);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 42, 83, 1) 0%,
    rgba(4, 12, 24, 0.8) 100%
  );

  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(0, 42, 83, 1) 0%,
    rgba(4, 12, 24, 0.8) 100%
  );

  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(0, 42, 83, 1) 0%,
    rgba(4, 12, 24, 0.8) 100%
  );

  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(0, 42, 83, 1) 0%,
    rgba(4, 12, 24, 0.8) 100%
  );

  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 42, 83, 1) 0%,
    rgba(4, 12, 24, 0.8) 100%
  );
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-background-fill-color: transparent;
}

.section__padding {
  padding: 2rem 3rem;
}

.section__margin {
  margin: 2rem 3rem;
}

.section {
  padding: 0.5rem;
  margin: 1.5rem;
  max-width: 100%;
}

.section__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container__row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.container__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/*desktops - higher resolution*/
@media (min-width: 1281px) {
}

/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {
}

/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

/*smartphones mobiles (portrait)*/
@media (min-width: 320px) and (max-width: 480px) {
  .section__padding {
    padding: 2rem 2rem;
  }

  .section__margin {
    margin: 2rem 2rem;
  }
}
