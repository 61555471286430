#valeed {
    /* z-index: -1; */
    color: #fdca0f;
    opacity: 0.1;
    font-family: 'rageItalic';
    font-size: 10rem;
    font-weight: bolder;
    position: absolute;
    margin: 100px 0px;
    transform: rotate(-15deg);
    line-height: 8rem;
}

.header {
    display: flex;
}

.header-content {
    flex: 1 1;
    display: flex;
    /* align-items: flex-start; */
    flex-direction: column;
    margin-right: 4rem;
}

.header-content h1 {
    font-family: var(--font-family);
    font-weight: bolder;
    font-size: 48px;
    line-height: 75px;
    letter-spacing: 0.04em;
}

.header-content p {
    font-family: var(--font-family);
    font-weight: bolder;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
    text-align: justify;
}

.header-content_input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.header-content_input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    border-radius: 5px 0px 0px 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.header-content_input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    background: #fdca0f;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bolder;
    line-height: 27px;
    color: #232428;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.header-content_input button:hover {
    text-decoration: underline;
}

.gradient__text {
    color: var(--color-text);
    justify-content: center;
}

.header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* svg#wallvLogo path {
    fill: transparent;
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    animation: wallvLogoAnim 2s 1 forwards;
} */

@keyframes wallvLogoAnim {
    0% {
        fill: transparent;
        stroke-dashoffset: 50;
    }
    100% {
        fill: #fdca0f;
        stroke-dashoffset: 0;
    }
}

.header-image svg#wallvLogo path {
    fill: transparent;
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    width: 70%;
    opacity: 0.6;
    animation: wallvLogoAnim 2s 1 forwards;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out, border-radius 0.3s ease-in-out;
}

.header-image svg#wallvLogo:hover {
    border-radius: 20px;
    animation-name: shake-center;
    animation-duration: 0.9s;
    opacity: 1;
    /* transform: rotate(10deg); */
    /* transition: transform 0.3s ease; */
    /*cursor: pointer;*/
    box-shadow: 0px 0px 12px #fdca0f;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
    }

@keyframes shake-center {

    0% {
        transform: rotate(0deg);
        transform: scale(1.1);
        transform-origin: 50% 50%;
    }

    10% {
        transform: rotate(8deg);
    }

    20% {
        transform: rotate(-10deg);
    }

    30% {
        transform: rotate(10deg);
    }

    40% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(10deg);
    }

    60% {
        transform: rotate(-10deg);
    }

    70% {
        transform: rotate(10deg);
    }

    80% {
        transform: rotate(-8deg);
    }

    90% {
        transform: rotate(8deg);
    }

    100% {
        transform: rotate(0deg);
        transform-origin: 50% 50%;
    }
}

/*desktops - higher resolution*/
@media (min-width: 1281px) {}
/*laptops, desktops*/
@media (min-width: 1025px) and (max-width: 1280px) {

    .header-content h1 {
        font-size: 28px;
        line-height: 32px;
        font-weight: bolder;
    }

    .header-content p {
        font-size: 18px;
        line-height: 22px;
        font-weight: bolder;
    }

    .header-content {
        font-size: small;
        margin: 4px;
        text-align: justify;
        line-height: 14px;
    }

}
/*tablets, ipads (portrait)*/
@media (min-width: 768px) and (max-width: 1024px) {
    .header-content h1{
        font-size: 28px;
        line-height: 32px;
        font-weight: bolder;
    }

    .header-content p {
        font-size: 18px;
        line-height: 22px;
        font-weight: bolder;
    }

    .header-content {
        font-size: small;
        margin: 4px;
        text-align: justify;
        line-height: 14px;
    }

    .header-content_input input {
        font-size: 16px;
    }

    .header-content_input button {
        font-size: 14px;
        padding: 4px;
        width: 30px;
    }

    .header-content_input button:hover {
        text-decoration: underline;
    }

}
/*tablets, ipads (landscape)*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .header-content h1{
        font-size: 22px;
        line-height: 32px;
        font-weight: bolder;
    }

    .header-content p {
        font-size: 16px;
        line-height: 18px;
        font-weight: bolder;
    }

    .header-content {
        font-size: small;
        margin: 4px;
        text-align: justify;
        line-height: 14px;
    }

    .header-content_input input {
        font-size: 16px;
        padding: 2px;
    }

    .header-content_input button {
        font-size: 12px;
        padding: 2px;
    }

}
/*low resolution tablets, mobiles (landscape)*/
@media (min-width: 481px) and (max-width: 767px) {

    .header {
        display: grid;
    }

    .header-content h1{
        font-size: 32px;
        line-height: 28px;
        font-weight: bolder;
    }

    .header-content p {
        font-size: 16px;
        text-align: justify;
        font-weight: bolder;
    }

    .header-content {
        font-size: small;
        margin: 4px;
        text-align: justify;
        line-height: 14px;
    }

    .header-content_input input {
        font-size: 14px;
        padding: 2px;
    }

    .header-content_input button {
        font-size: 14px;
        padding: 2px;
    }

}
/*smartphones mobiles (portrait)*/
@media (max-width: 480px) {

    .header {
        display: grid;
    }

    .header-content h1 {
        font-size: 20px;
        line-height: 22px;
        font-weight: bolder;
    }

    .gradient__text {
        font-size: 22px;
        line-height: 18px;
    }

    .header-content p {
        font-size: 16px;
        line-height: 18px;
        font-weight: bolder;
    }

    .header-content {
        font-size: small;
        margin: 4px;
        text-align: justify;
        line-height: 14px;
    }

    .header-content_input input {
        font-size: 12px;
        padding: 6px;
    }

    .header-content_input button {
        font-size: 14px;
        padding: 4px;
    }

    .header-img img {
        display: block;
    }

}
