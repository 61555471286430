:root {
  --width: 100%;
  --quantity: 4;
}

.slider {
  width: auto;
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
}

.slider .list {
  display: flex;
  width: 100%;
  height: 60px;
  min-width: calc(var(--width) - var(--quantity));
  position: relative;
}

.slider .list .item:nth-child(3n + 1),
.slider .list .item:nth-child(n) {
  --position: attr(position);
  display: flex;
  margin-right: calc(var(--position) * (var(--position) * 10px));
  width: auto;
  height: 60px;
  position: absolute;
  left: calc(var(--position) * 2rem);
  animation: autoRun calc((var(--quantity) * 5) * 1s) linear infinite;
  animation-delay: calc(((var(--quantity) * 5) * attr(position number 1)) * 4s);
}

@keyframes autoRun {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}

.slider .list .item .img {
  width: var(--width);
  height: var(--height);
  object-fit: cover;
}

/* Responsive */

@media (min-width: 1025px) {
  :root {
    --quantity: 8;
  }

  .slider .list .item {
    animation-duration: calc(var(--quantity) * 3s);
    animation-delay: calc((var(--quantity) * 3s) * var(--position));
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
}

@media (min-width: 481px) and (max-width: 768px) {
  .slider .list .item {
    width: calc(var(--width) / 2);
    height: calc(var(--height) / 2);
  }
}

@media (max-width: 480px) {
  .slider .list .item {
    width: calc(var(--width) / 3);
    height: calc(var(--height) / 3);
  }
}
