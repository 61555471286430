.banner {
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.banner .slider {
  position: absolute;
  width: 200px;
  height: 250px;
  top: 10%;
  left: calc(50% - 100px);
  transform-style: preserve-3d;
  transform: perspective(600px);
  animation: autoRun 18s linear infinite;
}

@keyframes autoRun {
  from {
    transform: perspective(600px) rotateY(0deg);
  }

  to {
    transform: perspective(600px) rotateY(360deg);
  }
}

.banner .slider .item {
  position: absolute;
  inset: 0 0 0 0;
  transform: rotateY(
      calc((var(--position) - 1) * (360 / var(--quantity)) * 1deg)
    )
    translateZ(550px);
}

.banner .slider .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  background: var(--color-bg);
  color: var(--color-text);
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.services .services__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  background: var(--color-bg);
  color: var(--color-text);
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}
